import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, DataTable, DefinitionList, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import uc1LsafetyTable from "../../../assets/UC-1L-052-safety-small-V6.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Safety Data and Adverse Reactions for KEYNOTE-052`,
    keywords: `keynote-052 adverse reactions, adverse reactions for first-line monotherapy for urothelial carcinoma`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the adverse reactions associated with the KEYNOTE-052 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/urothelial-carcinoma-second-line-monotherapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with locally advanced or metastatic urothelial carcinoma (mUC) who have disease progression during or following platinum-containing chemotherapy or within 12 months of neoadjuvant or adjuvant treatment with platinum-containing chemotherapy."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/urothelial-carcinoma-second-line-monotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 15 ;
const jobCode = jobCodes[15].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const additionalInformationFootnotes = [
    {
        label: "c.",
        text: "Graded per National Cancer Institute-Common Terminology Criteria for Adverse Events (NCI-CTCAE) v4.0."
    },
    {
        label: "d.",
        text: "Includes diarrhea, colitis, enterocolitis, gastroenteritis, and frequent bowel movements."
    },
    {
        label: "e.",
        text: "Includes fatigue and asthenia."
    },
    {
        label: "f.",
        text: "Includes back pain, bone pain, musculoskeletal chest pain, musculoskeletal pain, myalgia, neck pain, pain in extremity, and spinal pain."
    },
    {
        label: "g.",
        text: "Includes dermatitis, dermatitis bullous, eczema, erythema, rash, rash macular, rash maculopapular, rash pruritic, rash pustular, skin reaction, 	dermatitis acneiform, seborrheic dermatitis, palmar-plantar erythrodysesthesia syndrome, and rash generalized."
    }
];

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;052' }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <PageSubSection title="Adverse Reaction Profile of KEYTRUDA in Platinum-Ineligible Patients With Locally Advanced or Metastatic Urothelial Carcinoma">
                                <DataTable
                                    title="Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA"
                                    mobileImage={() => <img src={uc1LsafetyTable} style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }} alt="" />}
                                    desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/UC-1L-052-safety-large-V3.png" alt="Safety Data for KEYNOTE-052" />}
                                />
                                <TextBlock>
                                    <h5>Additional Information</h5>
                                    <p>KEYTRUDA was discontinued due to adverse reactions in 11% of patients.</p>
                                    <p>5% of patients (n=18/370) died from causes other than disease progression. 1.4% of patients (n=5/370) who were treated with KEYTRUDA experienced sepsis which led to death, and 0.8% of patients (n=3/370) experienced pneumonia which led to death.</p>
                                    <p>Adverse reactions leading to interruption of KEYTRUDA occurred in 22% of patients; the most common (≥1%) were liver enzyme increase, diarrhea, urinary tract infection, acute kidney injury, fatigue, joint pain, and pneumonia.</p>
                                    <p>Serious adverse reactions occurred in 42% of patients. The most frequent serious adverse reactions (≥2%) were urinary tract infection, hematuria, acute kidney injury, pneumonia, and urosepsis.</p>
                                </TextBlock>
                                <DefinitionList footnotes={additionalInformationFootnotes} />
                            </PageSubSection>
                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>
                </TemplateColumnWithSidebarContainer>
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
